/**
 * Attaches a click event listener to elements with a specific attribute value
 * to open a share link in a new tab when clicked.
 *
 * @param {string} sharePlatform - The platform to share the link on.
 * @param {Function} getLinkCallback - The callback function that returns the share link.
 * @param {string} productURL - The URL of the product to be shared.
 */
const createShareLinkHandler = (sharePlatform, getLinkCallback, productURL) => {
    const elements = document.querySelectorAll(`[data-share="${sharePlatform}"]`);
    elements.forEach(el => {
        el.addEventListener('click', () => openLinkInNewTab(getLinkCallback, productURL));
    });
}

/**
 * Generates a WhatsApp share link with the provided product URL.
 *
 * @param {string} productURL - The URL of the product to be shared.
 * @returns {string} - The generated WhatsApp share link.
 */
const getWhatsAppLink = (productURL) => {
    return 'https://api.whatsapp.com/send?text=' + encodeURIComponent('Schau dir dieses tolle Produkt an: ' + productURL);
}

/**
 * Generates the Facebook share link for a given product URL.
 *
 * @param {string} productURL - The URL of the product to be shared.
 * @returns {string} - The Facebook share link.
 */
const getFacebookLink = (productURL) => {
    return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(productURL);
}

/**
 * Opens a link in a new tab.
 *
 * @param {Function} getLinkCallback - A callback function that returns the link to open.
 * @param {string} productURL - The URL or identifier of the product.
 */
const openLinkInNewTab = (getLinkCallback, productURL) => {
    const link = getLinkCallback(productURL);
    window.open(link, '_blank');
}

/**
 * Creates a temporary input element and appends it to the document body.
 *
 * @param {String} value - The value to set for the input element.
 * @returns {HTMLInputElement} - The created input element.
 */
const createTempInput = (value) => {
    const tempInput = document.createElement('input');
    tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    return tempInput;
}

/**
 * Copies a link to the clipboard.
 *
 * @param {string} productURL - The URL of the product link to be copied to the clipboard.
 * @returns {void}
 */
const copyLinkToClipboard = (productURL) => {
    const tempInput = createTempInput(productURL);
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    alert('Produktlink wurde in die Zwischenablage kopiert.');
}

/**
 * Creates share links for WhatsApp, Facebook, and Copy Link.
 *
 * @function shareLinks
 * @returns {void}
 */
const shareLinks = () => {
    const productURL = window.location.href;
    createShareLinkHandler('whatsapp', getWhatsAppLink, productURL);
    createShareLinkHandler('facebook', getFacebookLink, productURL);
    createShareLinkHandler('copylink', copyLinkToClipboard, productURL);
}

document.addEventListener("DOMContentLoaded", shareLinks);