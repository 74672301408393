import Swiper, { Pagination, EffectFade, Navigation, Autoplay, Mousewheel } from 'swiper'
import _throttle from "lodash.throttle";

const slider = (widget = null) => {

    let sliderElements;

    if (widget) {
        sliderElements = document.querySelectorAll(`${widget} .c-slider`)
    } else {
        sliderElements = document.querySelectorAll('.c-slider')
    }

    if (sliderElements) {
        sliderElements.forEach(sliderEl => {
            let slider;

            let paginationType = 'fraction';
            let paginationClass;

            if (sliderEl.querySelector('.c-slider__pagination') !== null ) {
                paginationType = 'bullets';
                paginationClass = '.c-slider__pagination';
            } else if (sliderEl.querySelector('.c-slider__fraction') !== null) {
                paginationType = 'fraction';
                paginationClass = '.c-slider__fraction';
            }

            let baseOptions = {
                init: false,
                modules: [Pagination, EffectFade, Navigation, Autoplay, Mousewheel],
                autoplay: true,
                loop: true,
                speed: 500,
                pagination: {
                    el: sliderEl.querySelector(paginationClass),
                    type: paginationType,
                    dynamicBullets: true,
                    dynamicMainBullets: 3,
                    clickable: true,
                },
                navigation: {
                    prevEl: '.c-slider__button--prev',
                    nextEl: '.c-slider__button--next',
                },
            }

            const {sliderSettings} = sliderEl.dataset
            let additionalSettings = {}

            if (sliderSettings) {
                additionalSettings = JSON.parse(sliderSettings)
            }

            const enableSlider = () => {
                slider = new Swiper(sliderEl.querySelector('.c-slider__container'), {...baseOptions, ...additionalSettings})

                if (sliderEl.classList.contains('c-slider--offgrid-nextonly')) {
                    slider.params.pagination.dynamicBullets = false;
                }

                slider.on('slideChange', (s) => {
                    setTimeout(function () {
                        slider.params.mousewheel.releaseOnEdges = false;
                    }, 500);
                })

                slider.on('reachBeginning reachEnd', (s) => {
                    setTimeout(function () {
                        slider.params.mousewheel.releaseOnEdges = true;
                    }, 750);

                })
            }

            const handleMobileSlider = () => {
                const mq = window.matchMedia('(min-width: 1024px)')

                if (mq.matches === true) {
                    if (slider !== undefined) {
                        slider.destroy(true, true);
                    }
                    sliderEl.classList.add('c-slider--is-grid');
                } else if (mq.matches === false) {
                    enableSlider()
                    slider.init()
                    sliderEl.classList.remove('c-slider--is-grid');
                }
            }

            if (additionalSettings['mobileOnly'] === true) {
                handleMobileSlider()

                window.addEventListener('resize', _throttle(handleMobileSlider, 500))
            } else {
                enableSlider()
                slider.init()
            }
        })
    }
}


window.addEventListener('DOMContentLoaded', () => {
    setTimeout (() => {
        slider(null)
    }, 500)
});
window.slider = slider