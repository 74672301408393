/**
 * Attaches a mouseover event listener to a tab item. When the tab item is hovered over,
 * it checks if the tab item is not selected and performs a click event to select it.
 *
 * @param {HTMLElement} tabItem - The tab item to attach the mouseover event listener.
 * @returns {void}
 */
const onTabMouseOver = (tabItem) => {
    tabItem.addEventListener('mouseover', () => {
        if (tabItem.getAttribute('aria-selected') === 'false') {
            tabItem.click();
        }
    });

    tabItem.addEventListener('click', () => {
        if(tabItem.ariaSelected === "true") {
            const itemId = tabItem.id;

            let tabContentItem;

            if(tabItem.classList.contains('e-n-tab-title')) {
                const tabParent = tabItem.closest('.e-n-tabs');
                tabContentItem = tabParent.querySelector(`.e-n-tabs-content div[aria-labelledby="${itemId}"]`)
            } else if(tabItem.classList.contains('bdt-tabs-item-title')) {
                const tabParent = tabItem.closest('.bdt-grid');
                tabContentItem = tabParent.querySelector(`.bdt-switcher-wrapper div[aria-labelledby="${itemId}"]`)
            }

            const tabItemLink = tabContentItem.querySelector('.tab-link a');

            window.location.href = tabItemLink.href;
        }
    });
}

/**
 * Makes tabs hoverable by adding event listeners to each tab item.
 */
const makeTabsHoverable = () => {
    const tabItems = document.querySelectorAll('#wrapper-navbar .bdt-tabs-item-title, #wrapper-navbar .e-n-tab-title');
    tabItems.forEach(onTabMouseOver);
}

window.addEventListener('DOMContentLoaded', makeTabsHoverable);