import _throttle from 'lodash.throttle'

const productCardExpandable = () => {
    const productCardContainer = document.querySelectorAll('.elementor-loop-container.elementor-grid');

    productCardContainer.forEach((container) => {

        const cards = container.querySelectorAll('.c-product-card');
        const gridWidth = container.offsetWidth
        const gridHeight = container.getBoundingClientRect().height;


        // set card width
        let itemWidth = cards[0] ? cards[0].offsetWidth : 0;

        // get the number of cols and rows in the grid
        const maxItemsCol = Math.floor(gridWidth / itemWidth)
        const maxItemsRow = Math.ceil(cards.length / maxItemsCol)

        container.dataset.itemRows = maxItemsRow
        container.dataset.itemCols = maxItemsCol


        //set row and column number for each card
        let i = 1
        let j = 1

        cards.forEach((card) => {
            if (itemWidth * i > gridWidth) {
                j++
                i = 1
            }

            card.dataset.itemCol = i
            card.dataset.itemRow = j

            card.dataset.scaleSetting = 'fromTop';

            if (j === maxItemsRow) {
                card.dataset.scaleSetting = 'fromBottom';
                card.classList.add('is-last-row');
            }

            i++;
        })

        // find neighbor of the current item
        cards.forEach((card) => {
            const col = parseInt(card.dataset.itemCol);
            const row = parseInt(card.dataset.itemRow);
            let overFlowItem;

            if (card.dataset.scaleSetting === 'fromBottom') {
                overFlowItem = container.querySelector(`[data-item-col="${col}"][data-item-row="${Math.ceil(row - 1)}"]`)
            } else if (card.dataset.scaleSetting === 'fromTop') {
                overFlowItem = container.querySelector(`[data-item-col="${col}"][data-item-row="${Math.ceil(row + 1)}"]`)
            }

            // add and remove classes for active item and the neighbor
            card.addEventListener('click', (e) => {
                const isOpen = card.classList.contains('is-active');
                // Check if a card is already open
                const isOtherCardOpen = Array.from(cards).some(c => c !== card && c.classList.contains('is-active'));

                if (isOpen && !isOtherCardOpen) {
                    card.classList.remove('is-active');
                    if(overFlowItem) {
                        setTimeout(() => {
                            overFlowItem.classList.remove('is-neighbor')
                        }, 500);
                    }
                } else {
                    //remove active class from the other card which was still open
                    cards.forEach(c => {
                        if (c !== card) {
                            c.classList.remove('is-active');
                            c.classList.remove('is-neighbor');
                        }
                    });
                    card.classList.add('is-active');
                    if(overFlowItem) {
                        overFlowItem.classList.add('is-neighbor')
                    }
                }
            });
        })


        // set expanded card height for each card
        cards.forEach(card => {
            const cardContainer = card.closest('[data-elementor-type="loop-item"]');
            const cardOffset = cardContainer.offsetTop;

            const cardHeight = card.getBoundingClientRect().height;
            const cardPadding = parseInt(getComputedStyle(card).paddingBottom.replace('px', ''), 10);
            const gapHeight = parseInt(getComputedStyle(container).rowGap.replace('px', ''), 10);
            const collapsedCardHeight = cardHeight + gapHeight;
            const expandedCardHeight = cardHeight + gapHeight + cardPadding + 5 + 'px';

            card.querySelector('.c-product-card__content').style.height = expandedCardHeight;
            card.style.setProperty('--card-height', `-${cardHeight}px`)

            if ((cardHeight + cardOffset) >= gridHeight - cardOffset) {
                card.style.setProperty('--last-row-card-height', `-${collapsedCardHeight}px`)
            }
        })
    });
};


window.addEventListener('DOMContentLoaded', productCardExpandable);
window.addEventListener('resize', _throttle(() => productCardExpandable(), 500))