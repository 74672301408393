const showActiveTab = () => {
    const currentPageUrl = window.location.href;
    const urlParts = currentPageUrl.split('#');
    const afterHash = urlParts[1];

    if(afterHash) {
        //Widget suchen
        const tabWidget = document.querySelector('.elementor-widget-n-tabs.anchor-tabs');

        //Tab mit gleichem Namen wie Hash suchen
        if(tabWidget) {
            const wantedTabItem = tabWidget.querySelector(`.e-n-tabs-heading .e-n-tab-title[id="${afterHash}"]`);

            //Tab aktiv machen
            if(wantedTabItem) {
                setTimeout (() => {
                    wantedTabItem.click();
                }, 200);
            }

        }
    }
}

window.addEventListener('DOMContentLoaded', showActiveTab);