import GLightbox from 'glightbox';

window.addEventListener('DOMContentLoaded', () => {
    setTimeout (() => {
        const lightbox = GLightbox({
            selector:  '.glightbox',
            touchNavigation: true,
            loop: true,
            autoplayVideos: true,
            closeButton: true,
            openEffect: 'zoom',
            closeEffect: 'fade',
            plyr: {
                config: {
                    muted: false,
                    hideControls: true,
                    youtube: {
                        noCookie: true,
                        rel: 0,
                        showinfo: 0,
                        iv_load_policy: 3
                    },
                    vimeo: {
                        byline: false,
                        portrait: false,
                        title: false,
                        speed: true,
                        transparent: false
                    }
                },
            }
        });

    }, 500)
});