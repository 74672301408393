/**
 * The `querySelectorElement` function selects and returns the first element in the document that matches the specified CSS selector.
 *
 * @param {string} selector - The CSS selector used to query the DOM.
 * @returns {Element | null} - The selected element or null if no matching element is found.
 */
const querySelectorElement = (selector) => document.querySelector(selector);

/**
 * Updates the classes of a given element by adding or removing classes.
 *
 * @param {HTMLElement} element - The element to update the classes for.
 * @param {Object} options - The options for updating the classes.
 * @param {string[]} options.addClasses - The classes to add to the element.
 * @param {string[]} options.removeClasses - The classes to remove from the element.
 */
const updateElementClasses = (element, {addClasses = [], removeClasses = []} = {}) => {
    removeClasses.forEach(className => element.classList.remove(className));
    addClasses.forEach(className => element.classList.add(className));
}

/**
 * Applies class changes to the specified element.
 *
 * @param {string} selector - The CSS selector for selecting the element.
 * @param {string[]} classes - The array of class names to be applied to the element.
 * @returns {void}
 */
const applyClassChangesToElement = (selector, classes) => {
    const element = querySelectorElement(selector);
    if (element) {
        updateElementClasses(element, classes);
    }
}

/**
 * Adds header button functionality to the elements with class 'is-nav-button'.
 * Applies class changes to the matching elements.
 * @function addHeaderButton
 * @returns {void}
 */
const addHeaderButton = () => {
    applyClassChangesToElement('.is-nav-button', {removeClasses: ['menu-item']});
    applyClassChangesToElement('.is-nav-button a', {addClasses: ['uicore-btn', 'uicore-inverted']});
}

window.addEventListener('DOMContentLoaded', addHeaderButton);

const addMobileSearchButton = () => {
    const container = document.querySelector('.uicore-header-wrapper .uicore.elementor-container');

    if(container) {

        const newElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        newElement.setAttribute('width','24px');
        newElement.setAttribute('height','24px');
        newElement.setAttribute('fill','none');
        newElement.setAttribute('viewbox','0 0 24 24');
        newElement.classList.add('c-mobile-menu__search','uicore-search-btn');
        newElement.innerHTML = '<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"></path>';

        container.appendChild(newElement);

        const searchForm = document.querySelector('.uicore-search .search-form > label');

        if(searchForm) {
            const submitButton = document.createElement('button');
            submitButton.type = 'submit';
            submitButton.classList.add('c-search-form-submit');
            submitButton.innerHTML = 'Suchen';

            searchForm.appendChild(submitButton);
        }

    }
}

window.addEventListener('DOMContentLoaded', addMobileSearchButton);