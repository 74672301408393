/**
 * @description Filter cards based on the select input field.
 *
 * @returns {void}
 */
function filterCardsOnInputSelection() {
    const cards = document.querySelectorAll('.c-term-grid__item');
    const inputs = document.getElementsByName('designer_store');

    // Selects input based on the current URL hash.
    function selectInputBasedOnUrl() {
        const urlHashValue = window.location.hash ? decodeURIComponent(window.location.hash.slice(1)) : '';
        for (const input of inputs) {
            // If URL hash matches the input value, check it and return.
            if (input.value === urlHashValue) {
                input.checked = true;
                return;
            }
        }
        // If no matches found, check the first input.
        if (inputs.length > 0) {
            inputs[0].checked = true;
        }
    }

    function getCheckedInput() {
        let selectedField = null;
        for (const input of inputs) {
            if (input.checked) {
                selectedField = input.value;
            }
        }
        return selectedField;
    }

    // Selects input based on the current URL hash (or the first input if none matches) at the beginning.
    selectInputBasedOnUrl();
    // Update cards visibility when an input's state changes.
    for (const item of inputs) {
        item.addEventListener("change", () => {
            const selectedField = getCheckedInput();
            updateCardsVisibility(cards, selectedField);

            // Update URL hash.
            history.pushState(null, '', '#' + encodeURIComponent(selectedField));
        });
    }
}

/**
 * Updates the visibility of cards based on the selectedField value.
 * @param {HTMLCollection} cards - The collection of cards to update visibility.
 * @param {null} selectedField - The selected field value to filter the visibility.
 */
function updateCardsVisibility(cards, selectedField) {
    if (cards && selectedField) {
        for (const card of cards) {
            if (card.dataset.store.includes(selectedField) || selectedField === 'all') {
                card.style.display = 'block';
            } else {
                card.style.display = 'none';
            }
        }
    }
}


document.addEventListener("DOMContentLoaded", filterCardsOnInputSelection);