/**
 * Beobachtet den Fortschritt von Datei-Uploads und fügt eine Klasse hinzu, wenn der Upload im Gange ist.
 *
 * @function observeFileUpload
 * @returns {void}
 */
const observeFileUpload = () => {
    // Finde alle zu beobachtenden Fortschrittsbalken für Datei-Uploads
    const observeTargets = document.querySelectorAll('.file_upload-container .nf-field-element .nf-fu-progress-bar');

    if (observeTargets) {
        // Iteriere durch alle zu beobachtenden Elemente
        observeTargets.forEach(observeTarget => {
            // Erstelle einen Observer für jedes zu beobachtende Element
            const observer = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    const progressBar = observeTarget.closest('.nf-fu-progress');

                    if (mutation.attributeName === 'style' && parseInt(observeTarget.style.width) > 0) {
                        // Überprüfen, ob 'in-progress' noch nicht hinzugefügt wurde
                        if (!progressBar.classList.contains('in-progress')) {
                            // Inhalt hat sich geändert, neue Klasse hinzufügen
                            progressBar.classList.add('in-progress');
                        }
                    } else if (mutation.attributeName === 'style' && parseInt(observeTarget.style.width) === 0) {
                        if (progressBar.classList.contains('in-progress')) {
                            // Inhalt hat sich geändert, Klasse entfernen
                            progressBar.classList.remove('in-progress');
                        }
                    }
                });
            });

            // Konfiguration für den Observer
            const observerConfig = {
                attributes: true,
                childList: true,
                subtree: true
            };

            // Starte den Observer für das zu beobachtende Element
            observer.observe(observeTarget, observerConfig);
        });
    }
}

// Füge einen Eventlistener für das DOMContentLoaded-Event hinzu, um die Funktion nach einer Verzögerung von 500ms aufzurufen
window.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        observeFileUpload();
    }, 500);
});
