const animateLogo = () => {
    const header = document.querySelector('.uicore-header-wrapper');


    let mqOnLoad = window.matchMedia('(min-width: 1026px)').matches;
    let logo;
    let svg;

    if(mqOnLoad) {
        logo = document.querySelector('.uicore-branding .uicore-logo.uicore-main');
        svg = document.querySelector('#morph-logo');
    } else {
        logo = document.querySelector('.uicore-branding .uicore-logo.uicore-mobile-main');
        svg = document.querySelector('#morph-logo-mobile');
    }

    if(!svg || !logo) {
        return;
    }

    const logoWidthInitial = svg.getBoundingClientRect().width;
    const logoWidthEnd = logo.getBoundingClientRect().width;

    const logoTopInitial = svg.getBoundingClientRect().top;
    const logoTopEnd = logo.getBoundingClientRect().top;

    const logoLeftInitial = svg.getBoundingClientRect().left;
    const logoLeftEnd = logo.getBoundingClientRect().left;


    window.addEventListener('scroll',  () => {
        let yPos = window.scrollY;

        if(mqOnLoad) {
            if (yPos > 80) {
                svg.style.top = logoTopEnd + "px";
                svg.style.width = logoWidthEnd + "px";

                if (yPos > 400) {
                    header.classList.remove('is-transparent');
                }

            } else if (yPos < 80) {
                if (document.querySelector('body.home')) {
                    header.classList.add('is-transparent');
                }
                svg.style.top = logoTopInitial + "px";
                svg.style.width = logoWidthInitial + "px";

                if (!mqOnLoad) {
                    svg.style.left = logoLeftInitial + "px";
                }
            }
        } else if(!mqOnLoad) {
            if (yPos > 70) {
                svg.style.top = logoTopEnd + "px";
                svg.style.width = logoWidthEnd + "px";
                svg.style.left = logoLeftEnd + "px";
                svg.style.transform = "translate3d(0, 0, 0)";

                if (yPos > 120) {
                    header.classList.remove('is-transparent');
                }
            } else if (yPos < 70) {
                if (document.querySelector('body.home')) {
                    header.classList.add('is-transparent');
                }
                svg.style.top = logoTopInitial+"px";
                svg.style.width = logoWidthInitial + "px";
                svg.style.left = "50%";
                //svg.style.left = logoLeftInitial + "px";
                svg.style.transform = "translate3d(-50%, -50%, 0)";
            }
        }

    });
}

const setHeaderTransparent = () => {

    let hero = document.querySelector('.c-slider--hero');
    if(!hero) {
        hero = document.querySelector('.c-animation-hero');
    }

    const header = document.querySelector('.uicore-header-wrapper');

    if (hero) {
        const heroHeight = hero.getBoundingClientRect().height;
        const headerHeight = header.getBoundingClientRect().height;

        header.classList.add('is-transparent');

        if (window.scrollY > ((heroHeight / 2) - headerHeight)) {
            header.classList.remove('is-transparent');
        } else {
            header.classList.add('is-transparent');
        }

        header.addEventListener('mouseenter', () => {
            header.classList.remove('is-transparent');
        });

        header.addEventListener('mouseleave', () => {
            if (window.scrollY <= ((heroHeight / 2) - headerHeight)) {
                header.classList.add('is-transparent');
            }
        });
        document.documentElement.style.setProperty('--page-header-height', `${headerHeight}px`);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    setTimeout (() => {
        animateLogo();
        setHeaderTransparent();
    }, 500)
});

window.addEventListener('resize', () => {
    animateLogo();
    setHeaderTransparent();
});



/*const animateHeroSvg = () => {

    const header = document.querySelector('.uicore-header-wrapper');
    const hero = document.querySelector('.c-animation-hero');
    const svg = document.querySelector('#morph-logo');

    let mqOnLoad = window.matchMedia('(min-width: 1026px)').matches;
    let logo;

    if(mqOnLoad) {
        logo = document.querySelector('.uicore-branding .uicore-logo.uicore-main');
    } else {
        logo = document.querySelector('.uicore-branding .uicore-logo.uicore-mobile-main');
    }

    const logoWidthInitial = svg.getBoundingClientRect().width;
    const logoWidthEnd = logo.getBoundingClientRect().width;

    const heroHeight = hero.getBoundingClientRect().height;
    const headerHeight = header.getBoundingClientRect().height;

    const logoTopEnd = logo.getBoundingClientRect().top;
    const logoLeftEnd = logo.getBoundingClientRect().left;


    c
        let yPos = window.scrollY,
            yPer = (yPos / ((heroHeight / 2) - headerHeight));

        if (yPer > 1) {
            yPer = 1;
        }

        const logoPos = (-1 * (yPer * 50) + 50);
        //const logoSize = ((headerHeight * yPer) - (logoHeight * yPer) + logoHeight);

        const valueDifference = logoWidthEnd - logoWidthInitial;
        const logoWidth = logoWidthInitial + valueDifference * yPer;


        svg.style.top = "calc("+logoPos + "% + "+logoTopEnd+"px)";
        svg.style.transform = "translate3d(-50%, -" + logoPos + "%, 0)";
        svg.style.width = logoWidth + "px";

        if(!mqOnLoad) {
            svg.style.left = "calc("+logoPos + "% + "+logoLeftEnd+"px)";
            svg.style.transform = "translate3d(-" + logoPos + "%, -" + logoPos + "%, 0)";
        }


        if (document.querySelector('body.home')) {
            if (yPos > ((heroHeight / 2))) {
                header.classList.remove('is-transparent');
            } else {
                header.classList.add('is-transparent');
            }
        } else {
            setHeaderTransparent();
        }

    });
}*/