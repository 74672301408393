const Accordion = require('vanilla-js-accordion');
/**
 * Configuration options for an accordion.
 *
 * @typedef {Object} AccordionOptions
 * @property {string} item - The selector for the accordion item elements.
 * @property {string} target - The selector for the accordion target elements.
 * @property {string} control - The selector for the accordion control elements.
 * @property {string} panel - The selector for the accordion panel elements.
 * @property {boolean} allowMultiple - Determines if multiple panels can be opened at once. Default is false.
 * @property {string} transition - The CSS transition property to be used for animating the panel height. Default is 'height .3s cubic-bezier(0.7, 0.6, 0.1, 0.8)'.
 */
const accordionOptions = {
    item: '.sidebar-item',
    target: '.sidebar-control',
    control: '.sidebar-control',
    panel: '.sidebar-facet',
    allowMultiple: false,
    transition: 'height .3s cubic-bezier(0.7, 0.6, 0.1, 0.8)',
};

/**
 * Deregisters an instance when facets are appended.
 *
 * @param {Object} instance - The instance to be deregistered.
 *
 * @returns {undefined}
 */
const deregisterInstanceWhenFacetsAppended = (instance) => {
    window.WP_Grid_Builder && window.WP_Grid_Builder.on('init', function (wpgb) {
        wpgb.facets.on('appended', () => {
            instance.destroy();
            collapse();
        });
    });
};

/**
 * Creates an instance of an accordion component.
 * @param {HTMLElement} AccordionElement - The accordion element to be initialized.
 */
const createAccordionInstance = AccordionElement => {
    const instance = new Accordion(AccordionElement, accordionOptions);
    deregisterInstanceWhenFacetsAppended(instance);
};

/**
 * Collapses the sidebar accordion elements.
 *
 * @function collapse
 * @returns {void}
 */
const collapse = () => {
    const accordionElements = document.querySelectorAll('.sidebar');
    if (accordionElements) {
        accordionElements.forEach(createAccordionInstance);
    }
};
window.addEventListener('DOMContentLoaded', collapse);
collapse();