/**
 * Toggles the 'hidden' class of an element based on the given condition.
 * If the condition is true, the 'hidden' class is removed from the element.
 * If the condition is false, the 'hidden' class is added to the element.
 *
 * @param {HTMLElement} element - The element to toggle the class on.
 * @param {boolean} condition - The condition to determine whether to add or remove the 'hidden' class.
 */
const toggleHiddenClass = (element, condition) => {
    if (condition) {
        element.classList.remove('hidden');
    } else {
        element.classList.add('hidden');
    }
}

/**
 * Checks if the provided DOM element has the 'hidden' class.
 *
 * @param {Element} accItem - The DOM element to check.
 * @returns {boolean} - Indicates whether the element is hidden or not.
 */
const isHidden = accItem => accItem.classList.contains('hidden');

/**
 * This function checks if text includes search query.
 *
 * @param {string} text - Text to check.
 * @param {string} searchQuery - Search input value.
 * @returns {boolean} - Indicates whether the text includes the search query or not.
 */
const textIncludesSearchQuery = (text, searchQuery) => text.toLowerCase().includes(searchQuery);

/**
 * Filters the search results based on the search input value.
 * The search query is case-insensitive.
 *
 * The function uses the provided search input value and search content
 * to search for matching results within a collapsible accordion structure.
 * It toggles the hidden class of the matching accordion items and the accordion title
 * based on whether they contain the search query or not.
 *
 * @param {string} searchInputValue - The search input value to match against.
 * @param {HTMLElement} searchContent - The container element holding the search results.
 * @returns {void}
 */
const filterResults = (searchInputValue, searchContent) => {
    const searchQuery = searchInputValue.toLowerCase();
    const accContainer = searchContent.querySelectorAll('.bdt-ep-accordion-container');
    accContainer.forEach(container => {
        const accordionItems = container.querySelectorAll('.bdt-ep-accordion-item');
        accordionItems.forEach(accItem => {
            const itemLabel = accItem.querySelector('.bdt-accordion-title .bdt-ep-title-text');
            const itemContent = accItem.querySelector('.bdt-accordion-content');

            const shouldToggle = [itemLabel.textContent, itemContent.textContent]
                .some(text => textIncludesSearchQuery(text, searchQuery));

            toggleHiddenClass(accItem, shouldToggle);

        });
        const listContainer = container.parentNode.closest('.elementor-widget-bdt-accordion');
        if (listContainer) {
            const accTitle = listContainer.previousElementSibling;
            const allAccordionItemsHidden = Array.from(accordionItems).every(isHidden);
            toggleHiddenClass(accTitle, !allAccordionItemsHidden);
        }
    });
}

/**
 * Initializes the search functionality for searchbars.
 *
 * The search function selects all elements with the class name ".c-search" and adds event listeners for input and reset actions.
 * When the input event is triggered on the search input, it calls the filterResults function to update the search results.
 * When the reset button is clicked, it clears the search input value and calls the filterResults function to reset the search results.
 *
 * @returns {void}
 */
const search = () => {
    const searchbars = document.querySelectorAll('.c-search');

    if (searchbars) {
        searchbars.forEach(search => {
            const searchInput = search.querySelector('.c-search__input');
            const searchClass = '#' + search.getAttribute('data-search');
            const searchContent = document.querySelector(searchClass);
            const searchReset = search.querySelector('.c-search__close');
            searchInput.addEventListener('input', () => filterResults(searchInput.value, searchContent));
            searchReset.addEventListener('click', () => {
                searchInput.value = '';
                filterResults(searchInput.value, searchContent);
            });
            filterResults(searchInput.value, searchContent);
        });
    }
}

document.addEventListener("DOMContentLoaded", search);