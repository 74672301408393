import {tns} from 'tiny-slider';
import bus from '../utils/eventBus'

window.addEventListener('DOMContentLoaded', function () {
    const gallery = document.querySelector('.c-image-gallery');

    if(gallery) {
        const slider = tns({
            container: '.c-image-gallery',
            items: 1,
            slideBy: 1,
            speed: 500,
            animateDelay: 200,
            mode: 'carousel',
            autoplay: true,
            autoplayTimeout: 3000,
            loop: true,
            mouseDrag: true,
            nav: false,
            controls: true,
            controlsText: [
                '<svg class="c-controls__btn"><use xlink:href="#icon-chevron-left"></use></svg>',
                '<svg class="c-controls__btn"><use xlink:href="#icon-chevron-right"></use></svg>'
            ],
            responsive: {
                768: {
                    items: 2,
                },
                1024: {
                    items: 6,
                    disable: true,
                    controls: false,
                }
            }
        });

        slider.events.on('indexChanged', () => {
            bus.emit('schrittweiter-tiny-update');
        });

        if (mobile()) {
            slider.goTo(2);
        }

        slider.play();
        window.slider = slider
    }
});


const mobile = () => {
    if (window.innerWidth < 1024) {
        return true;
    }
    return false;
};

//document.addEventListener('DOMContentLoaded', desktop);