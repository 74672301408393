/**
 * Object containing CSS selectors used in the application.
 *
 * @type {Object.<string, string>}
 * @property {string} SIDEBAR - The CSS selector for the sidebar element.
 * @property {string} BUTTON - The CSS selector for the filter button.
 * @property {string} DROPDOWN_ITEM - The CSS selector for the dropdown menu items.
 * @property {string} CLOSE_BTN - The CSS selector for the close button.
 * @property {string} SIDEBAR_ITEMS - The CSS selector for the sidebar items.
 * @property {string} SIDEBAR_CONTROL - The CSS selector for the sidebar control element.
 * @property {string} SIDEBAR_CONTROL_TITLE - The CSS selector for the sidebar control title element.
 * @property {string} DROPDOWN_TEXT - The CSS selector for the dropdown text element.
 * @property {string} SIDEBAR_SEARCH_INPUT - The CSS selector for the sidebar search input element.
 * @property {string} SIDEBAR_FACETS - The CSS selector for the sidebar facet elements.
 * @property {string} SIDEBAR_FACETS_ITEM - The CSS selector for the sidebar facet items.
 * @property {string} SIDEBAR_FACETS_RESULT_BUTTON - The CSS selector for the sidebar result button.
 * @property {string} WPGB_CHECKBOX_LABEL - The CSS selector for the WPGB checkbox label element.
 * @property {string} SEARCH_QUERY - The CSS selector for the sidebar search query element.
 */
const SELECTORS = {
    SIDEBAR: '.sidebar',
    BUTTON: 'filter',
    DROPDOWN_ITEM: '.dropdown-item',
    CLOSE_BTN: '.close',
    SIDEBAR_ITEMS: '.wpgb-hierarchical-list li',
    SIDEBAR_CONTROL: '.sidebar-control',
    SIDEBAR_CONTROL_TITLE: '.sidebar-control .elementor-heading-title',
    DROPDOWN_TEXT: '.dropdown-text .elementor-heading-title',
    SIDEBAR_SEARCH_INPUT: '.sidebar-search input',
    SIDEBAR_FACETS: '.elementor-widget-wpgb-facet',
    SIDEBAR_FACETS_ITEM: '.sidebar-facet li',
    SIDEBAR_FACETS_RESULT_BUTTON: '.sidebar-results',
    WPGB_CHECKBOX_LABEL: '.wpgb-checkbox-label',
    SEARCH_QUERY: '.sidebar-search',
};

/**
 * Represents the key code for the Esc key.
 *
 * @type {number}
 * @constant
 */
const ESC_KEYCODE = 27;

/**
 * Constructs the HTML content for the inner part of the sidebar.
 *
 * @param {HTMLElement} sidebarElement - The sidebar element to extract the inner HTML from.
 * @returns {string} The HTML content for the inner part of the sidebar.
 */
function constructSidebarInnerHtml(sidebarElement) {
    return `<div class="close"><div>Filter schließen</div><div class="closebtn"><span></span><span></span></div></div>${sidebarElement.innerHTML}`;
}

/**
 * Toggles the 'active' class of the given element.
 *
 * @param {HTMLElement} element - The element for which to toggle the 'active' class.
 */
function toggleActiveClass(element) {
    element.classList.contains('active') ? element.classList.remove('active') : element.classList.add('active');
}

/**
 * Filters the results based on the search query.
 *
 * @param {HTMLInputElement} sidebarSearchInput - The input element where the search query is entered.
 * @param {HTMLElement} parent - The parent element containing the list items to be filtered.
 *
 * @return {void}
 */
function filterResults(sidebarSearchInput, parent) {
    const searchQuery = sidebarSearchInput.value.toLowerCase();
    const listItems = parent.querySelectorAll(SELECTORS.SIDEBAR_FACETS_ITEM);
    listItems.forEach(listItem => {
        const itemLabel = listItem.querySelector(SELECTORS.WPGB_CHECKBOX_LABEL);
        const itemName = itemLabel.textContent.toLowerCase();
        if (itemName) {
            listItem.style.display = itemName.includes(searchQuery) ? 'block' : 'none';
        }
    });
}

document.addEventListener('DOMContentLoaded', setUpUI);

/**
 * Sets up the User Interface (UI) by modifying the HTML elements.
 *
 * @function setUpUI
 *
 * @return {undefined} - This function returns nothing.
 */
function setUpUI() {
    const sidebarElement = document.querySelector(SELECTORS.SIDEBAR);
    if (!sidebarElement || (!document.getElementById(SELECTORS.BUTTON) && !document.querySelectorAll(SELECTORS.DROPDOWN_ITEM))) {
        return;
    }

    sidebarElement.innerHTML = constructSidebarInnerHtml(sidebarElement);
    attachEventListeners(sidebarElement);
}

/**
 * Attaches event listeners to the button, dropdown buttons, close button, and document.
 * Clicking on the button or close button toggles the sidebar's visibility.
 * Pressing the Escape key also toggles the sidebar's visibility.
 * Clicking on a dropdown button selects the corresponding sidebar control and triggers a click event.
 *
 * @param {Element} sidebarElement - The sidebar element to attach event listeners to.
 * @return {void}
 */
function attachEventListeners(sidebarElement) {
    const button = document.getElementById(SELECTORS.BUTTON);
    const dropdownButtons = document.querySelectorAll(SELECTORS.DROPDOWN_ITEM);
    const sidebar = document.querySelector(SELECTORS.SIDEBAR);
    const closeBtn = document.querySelector(SELECTORS.CLOSE_BTN);
    const resultBtn = document.querySelector(SELECTORS.SIDEBAR_FACETS_RESULT_BUTTON);

    if(!button || !dropdownButtons || !sidebar || !closeBtn || !resultBtn) {
        return
    }

    /**
     * Toggles the sidebar element and its items based on the dropdown item selected.
     * @param {HTMLElement} dropdownItem - The dropdown item that was selected.
     */
    const toggleSidebar = function(dropdownItem) {

        const sidebarItems = document.querySelectorAll(SELECTORS.SIDEBAR_ITEMS);

        sidebarItems.forEach(item => {
            toggleActiveClass(item);
        });

        if(dropdownButtons && dropdownItem) {

            const dropdownText = dropdownItem.querySelector(SELECTORS.DROPDOWN_TEXT).innerHTML;
            const sidebarControls = sidebar.querySelectorAll(SELECTORS.SIDEBAR_CONTROL);

            sidebarControls.forEach(control => {
                const sidebarControlText = control.querySelector(SELECTORS.SIDEBAR_CONTROL_TITLE).innerHTML;

                if (dropdownText === sidebarControlText) {
                    control.click();
                }

            })
        }

        toggleActiveClass(button);
        toggleActiveClass(closeBtn);
        toggleActiveClass(resultBtn);
        toggleActiveClass(sidebarElement);

    };

    function escapeKeyListener(e) {
        if (e.keyCode === ESC_KEYCODE) {
            toggleSidebar();
        }
    }

    button.addEventListener('click', () => toggleSidebar());
    closeBtn.addEventListener('click', () => toggleSidebar());
    resultBtn.addEventListener('click', () => toggleSidebar());
    document.addEventListener('keyup', escapeKeyListener);

    if (dropdownButtons) { dropdownButtons.forEach(btn => { btn.addEventListener('click', () => toggleSidebar(btn)) }) };

    document.querySelectorAll(SELECTORS.SEARCH_QUERY).forEach(search => attachSearchListener(search));
}

/**
 * Attaches a search listener to the given search element.
 *
 * @param {HTMLElement} search - The search element to attach the listener to.
 * @return {void}
 */
function attachSearchListener(search) {
    const parent = search.parentNode;
    const sidebarSearchInput = parent.querySelector(SELECTORS.SIDEBAR_SEARCH_INPUT),
        sidebarFacets = parent.querySelector(SELECTORS.SIDEBAR_FACETS);
    sidebarSearchInput.addEventListener('input', () => filterResults(sidebarSearchInput, parent));
    sidebarFacets.addEventListener('change', () => filterResults(sidebarSearchInput, parent));

    filterResults(sidebarSearchInput, parent);
}