/**
 * @typedef {Object} Selectors
 * @property {string} MENU - CSS selector for the mobile menu container
 * @property {string} BUTTON - CSS selector for the mobile menu button
 * @property {string} ITEM - CSS selector for the mobile menu item
 * @property {string} DROPDOWN - CSS selector for the mobile menu dropdown
 * @property {string} SUBBUTTON - CSS selector for the mobile menu subbutton
 * @property {string} SUBITEM - CSS selector for the mobile menu subitem
 * @property {string} ACTIVE - CSS class name for the active state
 */
const SELECTORS = {
    MENU: '.c-mobile-menu',
    BUTTON: '.c-mobile-menu__button',
    ITEM: '.c-mobile-menu__item',
    DROPDOWN: '.c-mobile-menu__dropdown',
    SUBBUTTON: '.c-mobile-menu__subbutton',
    SUBITEM: '.c-mobile-menu__subitem',
    ACTIVE: 'is-active',
};

/**
 * Attaches and manages event listeners to a collection of elements matching a given selector within a parent element.
 *
 * @param {Element} element - The parent element to which the event listeners will be attached.
 * @param {string} selector - The CSS selector used to find the elements within the parent element.
 * @param {Function} eventListener - The event listener function to be attached to the matching elements.
 * @return {void}
 */
function manageEventListener(element, selector, eventListener) {
    const elements = element.querySelectorAll(selector);
    elements.forEach(el => {
        el.removeEventListener("click", eventListener);
        el.addEventListener("click", eventListener);
    });
}

/**
 * Performs an action when a click event is triggered.
 *
 * @param {Event} event - The click event.
 * @return {void}
 */
function clickEventListener(event) {
    const button = event.target.closest(SELECTORS.BUTTON);
    if (button) {
        const item = button.closest(SELECTORS.ITEM);
        processItemClick(button, item);
    }
}

/**
 * Process drop-down click event.
 *
 * @param {Event} event - The click event object.
 *
 * @return {void}
 */
function processDropDownClick(event) {
    const subItem = event.target.closest(SELECTORS.SUBITEM);
    processItemClick(event.target, subItem);
}

/**
 * Adds event listener to menus.
 *
 * @param {Array} menus - The array of menus.
 */
function addEventListenerToMenus(menus) {
    menus.forEach(menu => {
        manageEventListener(menu, SELECTORS.BUTTON, clickEventListener);
    });
}

// In case of dropdown
/**
 * Attaches an event listener to a dropdown element.
 *
 * @param {HTMLElement} dropdown - The dropdown element to attach the event listener to.
 * @returns {void}
 */
function attachEventListenerToDropDown(dropdown) {
    manageEventListener(dropdown, SELECTORS.SUBBUTTON, processDropDownClick);
}

/**
 * Process the click event on an item.
 *
 * @param {Element} clickedElement - The element that was clicked.
 * @param {Element} item - The item that was clicked.
 * @return {void}
 */
function processItemClick(clickedElement, item) {
    const dropdown = item.querySelector(SELECTORS.DROPDOWN);
    if (dropdown) {
        dropdown.classList.toggle(SELECTORS.ACTIVE);
        clickedElement.classList.toggle(SELECTORS.ACTIVE);
        attachEventListenerToDropDown(dropdown);
    }
}

/**
 * Runs the menu functionality.
 *
 * @function runMenu
 * @returns {undefined}
 */
function runMenu() {
    const menus = document.querySelectorAll(SELECTORS.MENU);
    if (menus) {
        addEventListenerToMenus(menus);
    }
}

document.addEventListener("DOMContentLoaded", runMenu);