import lozad from 'lozad'
import bus from '../utils/eventBus'

const lazy = () => {
    const lazyEl = document.querySelector('.u-lazy')

    if (lazyEl) {
        const observer = lozad('.u-lazy', {
            rootMargin: '10px 0px',
            threshold: 0.1,
            loaded: function (el) {
                const imgWrapper = el.closest('.c-img')
                if (imgWrapper) {
                    const placeholder = imgWrapper.querySelector('.c-img__placeholder')
                    if (placeholder) {
                        let bgImage = placeholder.style.backgroundImage
                        bgImage = bgImage.replace('url("', '').replace('")', '')

                        if (bgImage.length > 0) {
                            imgWrapper.classList.add('c-img--loaded')
                        }
                    }
                }
                bus.emit('schrittweiter-image-loaded', true)
            },
        })
        const observe = () => observer.observe()
        observe()

        document.addEventListener('DOMContentLoaded', () => observe, {
            once: true,
        })

        bus.on('schrittweiter-elementor-update', () => observe());
        bus.on('schrittweiter-tiny-update', () => observe());
        bus.on('schrittweiter-wpgb-update', () => observe());
    }
}

window.addEventListener('DOMContentLoaded', lazy)
window.lazyloader = lazy;


document.addEventListener('DOMContentLoaded', function () {
    window.WP_Grid_Builder && WP_Grid_Builder.on('init', function (wpgb) {
        wpgb.facets.on( 'appended', function () {
            bus.emit('schrittweiter-wpgb-update');
        });
    });
});

/**
 * Beobachtet Elemente mit der Klasse 'designer-loop' auf Mutationen und sendet ein Event bei Änderungen.
 *
 * @function observeLoop
 * @returns {void}
 */
const observeLoop = () => {
    // Finde alle zu beobachtenden Elemente mit der Klasse 'designer-loop'
    const observeTargets = document.querySelectorAll('.designer-loop, .elementor-loop-container');

    if (observeTargets) {
        // Iteriere durch alle zu beobachtenden Elemente
        observeTargets.forEach(observeTarget => {
            // Erstelle einen Observer für jedes zu beobachtende Element
            const observer = new MutationObserver(() => {
                // Bei einer Mutation sende das benutzerdefinierte Event 'schrittweiter-wpgb-update' über den Event Bus
                bus.emit('schrittweiter-wpgb-update');
            });

            // Konfiguration für den Observer
            const observerConfig = {
                attributes: true,
                childList: true,
                subtree: true
            };

            // Starte den Observer für das zu beobachtende Element
            observer.observe(observeTarget, observerConfig);
        });
    }

    // Sende das benutzerdefinierte Event 'schrittweiter-wpgb-update' über den Event Bus nach dem Laden der Seite
    bus.emit('schrittweiter-wpgb-update');
}

// Füge einen Eventlistener für das DOMContentLoaded-Event hinzu, um die Funktion beim Laden der Seite aufzurufen
window.addEventListener('DOMContentLoaded', observeLoop);